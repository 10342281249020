import React, { Fragment } from "react";
import ProductData from '../../data/inbi-products/product-one'
import PageWrapper from "../../components/PageWrapper";
import ProductContent from "./ProductContent";

const ProductDetails = () => {

	const serviceID = new URLSearchParams(window.location.search).get("id");
	const service = ProductData.find((serviceItem) => serviceItem.id === parseInt(serviceID));

	return (
		<Fragment>

			<PageWrapper classes="single_service_section">
				<ProductContent
					service={service}
				/>
			</PageWrapper>

		</Fragment>
	);
};

export default ProductDetails;
