import React from 'react';
 
const COntactInfo = () => {

    return (

      <div className="inbi-contact" >
          <div>For more information:</div>
          <div className='inbi-list'>
            <ul>
              <li>email: <a href="mailto:steve@3d-group.co.uk">steve@3d-group.co.uk</a></li>
              <li>Tel: +44 (0) 1623 812 604</li>
            </ul>
          </div>
          {/*<img className="inbi_img_size_small" src={(process.env.PUBLIC_URL + '/assets/images/inbi-3d-eng-logo.svg')} alt="logo" /> */}
      </div>

    );
}

export default COntactInfo;