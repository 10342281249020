import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/inbi-layout"; 
import About from '../components/inbi-about/home-one'

const Aboutpage = () => {
 
    return(
        <Fragment>

            <MetaTags>
              <title>inbisense | About</title>
              <meta
                name="description"
                content="inbisense - the world's first closed loop respiratory treatment system."
              />
            </MetaTags>

            <LayoutOne>
                <About />         
            </LayoutOne>
        </Fragment>
            
    ); 
};

export default Aboutpage;   