import React from 'react';
import NavbarItem from './inbi-navbaritem'
import MobileMenu from "../../inbi-mobile-menu/MobileMenu";
import MobileBtn from "../../mobile-menu/MobileBtn";
import { Link } from 'react-router-dom';

function Navbar(props) {

    return (

        <div>
            <div className="menu_area">
                <div className="navigation">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">

                                {/* Logo */}
                                <div className="header_logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/inbi-logo.svg'} alt="" />
                                    </Link>
                                </div>

                                <div className="checkmobile">
                                    <div className="inbi_header vertical-center">
                                        <div className="inbi_large">The world's first<span className="inbi_large_highlight"> closed loop </span><br />respiratory treatment system</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            
                <div className="inbi_background_highlight">
                    <div className="inbi_curve" >
                        <img src={process.env.PUBLIC_URL + '/assets/images/inbi-blue-curve-thin-mask.svg'} alt="" />
                    </div>
                    <div className="inbi_navigation">
                        <div className="inbi-row">
                            <div className="col-sm-12 inbi_navbar_navitem">

                                {/* Mobile Menu */}

                                <MobileBtn />

                                <MobileMenu />

                                {/* End:  Mobile Menu */}

                                {/* Start:  NAVIGATION  */}

                                <div id="navigation" className="inbi_navbar_navitem">
                                    <ul>
                                        <NavbarItem />
                                    </ul>
                                </div>

                                {/* End: NAVIGATION  */}

                            </div>
                        </div>
                    </div>
                    <div className="inbi_banner" style={{ backgroundImage: `url("${process.env.PUBLIC_URL + '/assets/images/inbi-top-banner-all-devices-extended.png'}")` }}>
                        <p >Digital Health Platform</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;