import React, { Fragment } from "react";  
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";  
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import {Link} from "react-router-dom"; 
 
import ServicesData from '../../data/inbi-products/product-one' 


const ServiceContentWrap = ({service}) => {
    return (
        <Fragment>

            <div className="col-md-9 col-sm-12 single_service_page_content"> 
                <div className="row">
                    <div className="col-sm">
                        <div className="single_service_title">
                            <img className="inbi_img_size_xlarge" src={(process.env.PUBLIC_URL + '/assets/images/' + service.titleImage)} alt="product" />
                            {<div dangerouslySetInnerHTML={{__html: service.description}}/>}
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <img className="inbi_img_size_medium2" src={(process.env.PUBLIC_URL + '/assets/images/' + service.titleHeaderImage)} alt="product" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="inbi_product_image_parent">
                                    <img className="inbi_img_size_large1 image2single" src={(process.env.PUBLIC_URL + '/assets/images/inbi-device-background-circle.svg')} alt="product" />
                                    <img className="inbi_img_size_medium1 image1single" src={(process.env.PUBLIC_URL + '/assets/images/' + service.singleThumb)} alt="product" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Sidebar classes={'col-md-3 col-sm-12 single_service_side_bar'}>
                <SidebarItem classes="single_service_category">
                    <List>
                        {
                            ServicesData.slice(0,7).map((serviceItem,id)=>(
                                <LI key={id}><i className="fa fa-angle-right"></i>
                                    <Link to={`${process.env.PUBLIC_URL + `/product/${serviceItem.title.split(' ').join('-').toLowerCase()}?id=${serviceItem.id}`}`}>
                                        {serviceItem.title}
                                    </Link>
                                </LI>
                            ))
                        }
                    </List> 
                </SidebarItem>
            </Sidebar>

        </Fragment>
    );
};

export default ServiceContentWrap;