import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";  
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
 
const Footer = () => {
 
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);
  
    useEffect(() => {
      setTop(100);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      animateScroll.scrollToTop();
    };
  
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    return(
    <footer className="footer-section">
        <div className="container">
            <div className="row"> 

              <div className="col-lg-3 col-sm-12 inbi_footer_top"> 
                3D Engineering Design Ltd
                Unit B3F, Pleasley Vale Business Park<br/>
                Mansfield<br/>
                Nottinghamshire<br/>
                NG19 8RL
                </div> 

                <div class="w3-container">
                  <p class="w3-tooltip">Privacy and Cookie Policy &nbsp; <span class="w3-text">(<em>&nbsp;3D Engineering Design Ltd respects your privacy. &nbsp; We do not use cookies or other trackers on this website &nbsp;</em>)</span></p>
                </div>

                {/*  Start:Subfooter  */}
                <div className="subfooter"> 
                    <div className="copyright_text"> &copy; {new Date().getFullYear()} 3D Engineering Design Ltd is a company registered in the UK registration number 03344825<Link to={process.env.PUBLIC_URL + "/"}> </Link>
                    </div> 

                    <button className={`scrollup ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()} > 
                        <span className="arrow_triangle-up"></span>
                    </button> 
                </div> 
                {/* End:Subfooter  */}

            </div>
        </div> 
    </footer> 
        )
}

export default Footer;