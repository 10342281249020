import React , {Fragment } from 'react';  
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/inbi-layout"; 
import ContactForm from '../components/inbi-contact'

const ContactPage = () => {
    
    return(

        <Fragment>
            <MetaTags>
              <title>inbisense | Contact</title>
              <meta
                name="description"
                content="inbisense - the world's first closed loop respiratory treatment system."
              />
            </MetaTags>

            <LayoutOne>

              <ContactForm />
 
            </LayoutOne>
        </Fragment>

      );
  }

export default ContactPage;   