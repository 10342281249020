import PropTypes from "prop-types";
import React from "react";

const SectionTitle = ({ title }) => {
  return (
 
    <div className="inbi_contact_title">
        {title}
    </div>
    
  ); 
};

SectionTitle.propTypes = {
    title: PropTypes.string,
  subtitle: PropTypes.string
};

export default SectionTitle;
