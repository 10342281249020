import React from 'react'; 
import {Link} from "react-router-dom"; 
 
  
function ServiceItem(props) {
    const serviceURL = `/product/${props.title.split(' ').join('-').toLowerCase()}?id=${props.id}`
    return ( 
 
    <div className="col-sm-12" style={{marginTop:"40px"}}> 
        <div className="service_list"> 

            <div className="service_img"> 
                <Link to={`${process.env.PUBLIC_URL + serviceURL}`}> 
                <div className="inbi_product_image_parent1">
                    <img className="inbi_img_size_large1a image2" src={(process.env.PUBLIC_URL + '/assets/images/inbi-device-background-circle.svg')} alt="product" />
                    <img className="inbi_img_size_large2a image1" src={(process.env.PUBLIC_URL + '/assets/images/' + props.thumb)} alt="product" />
                </div>
                </Link>
            </div>

            <div className="row">
                <div className="col-sm">
                    {/* <img className="inbi_img_size_medium3" src={(process.env.PUBLIC_URL + '/assets/images/' + props.titleHeaderImage)} alt="product" /> */}
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <div className="service_para"> 
                        <img className="inbi_img_size_xlarge1 image1" src={(process.env.PUBLIC_URL + '/assets/images/' + props.titleImage)} alt="product" />
                        {<p dangerouslySetInnerHTML={{__html: props.shortDesc}}/>}
                        <Link to={`${process.env.PUBLIC_URL + serviceURL}`} className="serv_read">{props.btnText} <span className="fa fa-angle-double-right"></span></Link>
                    </div> 
                </div>
            </div>
        </div> 
    </div> 

    );
}

export default ServiceItem;

 