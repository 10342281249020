import React from 'react';   
import AboutList from "./AboutList"; 
import SectionTitle from "../../inbi-section-titles/SectionTitle";
import AboutData from "../../../data/About/about-one";
 
const AboutContent = () => {

  return (
 
	<section className="about-section" id="about">
                                {/* Start: Heading */}
                                <SectionTitle 
                        title=" About "  
                        />
        <div className="container"> 
            <div className="row">
                <div className="col-md-6 col-sm-12 about_bottom_warp">
                    <div className="about_list">

                        <p>Inbisense are currently developing the world’s first truly closed loop respiratory treatment system</p>
                        <p>This includes a range of devices for various conditions and specific patient needs</p>
                        <p>Our flagship product offers a combination of on board diagnostics with variable dose delivery. This provides patient specific precision dosing in a single, easy to use device</p>
                        <p>It is our mission to lower both patient and health care professional burden, improve patient outcomes and provide a significant cost saving to the wider healthcare system</p>
                     </div>
                </div>

                {  /*Start: About Image  */} 
                <div className="col-md-6 col-sm-12"> 

                    { /*  About Right Image  */}
                    <img className="inbi_img_size_xxlarge" src={process.env.PUBLIC_URL + '/assets/images/closed_illustration_gradient_v03.png'} alt="" />

                </div> 
                {/*End: About Image  */}

            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>
  );
};

export default AboutContent;
