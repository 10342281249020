import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/inbi-layout"; 
import Products from '../components/inbi-products/home-one' 

const productPage = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title>inbisense | Products</title>
		  <meta
			name="description"
			content="inbisense - the world's first closed loop respiratory treatment system."
		  />
		</MetaTags>
		<LayoutOne>
		<div className="service-page">
			<Products /> 
		</div>
		</LayoutOne>
	  </Fragment>
	);
  };
  
  export default productPage;



