import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import InbiLayout from "../layouts/inbi-layout";
import About from '../components/inbi-about/home-one'
import Products from '../components/inbi-products/home-one'
import ContactForm from '../components/inbi-contact'

const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>inbisense | Home</title>
        <meta
          name="description"
          content="inbisense - the world's first closed loop respiratory treatment system."
        />
      </MetaTags>
      <InbiLayout>
        <About />
        <Products />
        <ContactForm />
      </InbiLayout>
    </Fragment>
  );
};

export default Home;
