import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/inbi-home";
import Product from "./pages/inbi-product";
import SingleProduct from "./pages/inbi-single-product";
import Gallery from "./pages/Gallery";
import SingleGallery from "./pages/SingleGallery";
import Team from "./pages/Team";
import Contact from "./pages/inbi-contact";
import SingleBlog from "./pages/SingleBlog";
import Blog from "./pages/Blog";
import About from "./pages/inbi-about";
import NotFound from "./pages/inbi-notfound";

function InbiApp() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home"}`}
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route exact path={`${process.env.PUBLIC_URL + '/products'}`}
            component={Product}
          />
          <Route path={`${process.env.PUBLIC_URL + '/product/:serviceIDD'}`}
            component={SingleProduct}
          />
          <Route path={`${process.env.PUBLIC_URL + '/gallery/:galleryID'}`}
            component={SingleGallery}
          />
          <Route path={`${process.env.PUBLIC_URL + '/blog/:blogID'}`}
            component={SingleBlog}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery"}`}
            component={Gallery}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/team"}`}
            component={Team}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            component={Blog}
          />
          <Route exact component={NotFound} />

        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default InbiApp;
